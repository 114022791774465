<template>
  <div class="page">
    <img src="@i/banner.png" class="banner">
    <div class="icon-list">
      <div class="icon-item" v-for="(item) in iconList" :key="item.label">
        <!-- <img :src="item.icon"> -->
        <span class="icon-img" :style="{ backgroundPosition: `-${item.index * 45}px 0` }"></span>
        <span>{{ item.label }}</span>
      </div>
    </div>
    <div class="g-introduce-container">
      <div class="g-introduce-content">
        <span class="icon_introduce"></span>
        <p class="g-introduce-content-title mt30">让新手也可以快速成为合格的投手</p>
        <p class="g-introduce-content-text mt30">抖脉传媒成立于2020年， 公司自成立以来一直致力于直播业务扩展，
          以及相关产品研发，并致力于让投放推广可以快速学习并投入实践，
          从萌生这个想法到实现，我们筹划1年。</p>
      </div>
      <img src="../../assets/images/case1.png" class="case ml20">
    </div>
    <div class="g-introduce-container mt30">
      <div class="g-introduce-content">
        <span class="icon_introduce"></span>
        <p class="g-introduce-content-title mt30">更便捷的操作方式更精确的算法支撑</p>
        <p class="g-introduce-content-text mt30"> 我们想成为一个人人皆会用的软件；
          能让推广投放变简单的工具；
          或兼具各类能力的产品....</p>
      </div>
      <img src="../../assets/images/case1.png" class="case ml20">
    </div>
    <div class="g-footer-container">
      <span class="g-footer-container-title">未来，我们想通过抖脉实现</span>
      <span class="g-footer-container-text">便捷高效的工作 | 直播数据汇总分析 | 新计划效果预估 | 数据检测预警独立化</span>
      <span class="g-footer-container-tip mt30">抖脉隶属于抖脉传媒，是一款简化投放难度的云端计算工具</span>
      <span class="g-footer-container-tip mt10">是广告投放推广开发团队必备工具。</span>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      iconList: [
        { index: 0, label: '便捷高效' },
        { index: 1, label: '云端算法' },
        { index: 2, label: '快速上升' },
        { index: 3, label: '多维检测' },
        { index: 4, label: '即时预警' }
      ]
      // caseList: [
      //   {
      //     caseImg: require('../../assets/images/case1.png'),
      //     title: '让新手也可以快速成为合格的投手',
      //     text:''
      //   }
      // ]
    }
  },
  created () { },
  methods: {
    // ------------------ 点击方法
    // ------------------ 请求方法
    // ------------------ 其它
  }
}
</script>
<style lang='less' scoped>
.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px;

  .banner {
    width: 100%;
    max-width: 1380px;
  }

  .icon-list {
    display: flex;
    flex-direction: row;
    margin: 87px 0 67px;
    padding: 0 140px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;

    .icon-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #282828;
      font-size: 18px;

      .icon-img {
        background: url('../../assets/images/sprite.png') no-repeat;
        width: 45px;
        height: 45px;
        margin-bottom: 19px;
        background-position: -45px 0;
        // background-position-x: -45px;
        // background-position-y: 0;
      }
    }
  }
}

.icon_introduce {
  background: url('../../assets/images/sprite.png') no-repeat 0 -150px;
  display: inline-block;
  width: 37px;
  height: 43px;
}
</style>
